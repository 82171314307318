import React from 'react';
import {Toast, ToastPlacement, ToastSkin} from 'wix-ui-tpa';
import {useControllerProps} from '../../../domain/controllers/ControllerContext';
import {classes} from './ErrorToast.st.css';
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor';

export enum ErrorToastDataHook {
  root = 'ErrorToast.root',
}

export const ErrorToast = () => {
  const {shouldShowError, clearError} = useControllerProps().cartStore;
  const {t} = useTranslation();
  const {isMobile} = useEnvironment();

  return shouldShowError ? (
    <Toast
      data-hook={ErrorToastDataHook.root}
      skin={ToastSkin.error}
      onClose={() => clearError()}
      className={classes.errorToast}
      placement={isMobile ? /* istanbul ignore next */ ToastPlacement.bottomFullWidth : ToastPlacement.inline}
      shouldAnimate
      shouldShowCloseButton>
      {t('sideCart.generalError.toast')}
    </Toast>
  ) : null;
};
